<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('irriPumpInformation.pump_scheduler') }}</h4>
      </template>
      <template v-slot:body>
      <b-row>
        <b-col md="5" sm="12" xs="12">
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('org_pro.organization')"
            label-for="org_id"
            >
            <b-form-select
              plain
              v-model="search.org_id"
              :options="organizationList"
              id="org_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
        </b-col>
        <b-col md="5" sm="12" xs="12">
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('irriPumpInformation.pump_id')"
            label-for="pump_id"
            >
            <b-form-select
              plain
              v-model="search.pump_id"
              :options="pumpInformationDropdownList"
              id="pump_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
        </b-col>
        <b-col sm="12" md="2">
      <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
        </b-col>
      </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('irriPumpInformation.pump_scheduler') }} {{ $t('globalTrans.list')}}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button class="btn_add_new" v-b-modal.modal-4 @click="resetId">
              <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(created_at)="data">
                      <span :title="data.item.created_at | dateFormat">{{ data.item.created_at | dateFromNow }}</span>
                    </template>
                    <template v-slot:cell(status)="data">
                      <b-badge pill variant="danger" v-if="data.item.status">{{ $t('globalTrans.inactive') }}</b-badge>
                      <b-badge pill variant="primary" v-else>{{ $t('globalTrans.active') }}</b-badge>
                    </template>
                    <template v-slot:cell(action)="data">
                      <!-- <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)" title="Edit"><i class="ri-ball-pen-fill m-0"></i></b-button>
                      <b-button :variant="data.item.status ? ' iq-bg-danger' : ' iq-bg-success'" size="sm" @click="remove(data.item)" title="Active/Inactive">
                          <i class="fas" :class="data.item.status ? 'fa-toggle-off' : 'fa-toggle-on'"></i>
                      </b-button> -->
                      <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 @click="edit(data.item)"><i class="ri-ball-pen-fill"></i></a>
                      <a href="javascript:" class="btn_table_action table_action_status" title="Active" v-if="data.item.status === 0" @click="remove(data.item)"><i class="fas fa-toggle-on"></i></a>
                      <a href="javascript:" class="btn_table_action table_action_toggle" title="Inactive" v-else @click="remove(data.item)"><i class="fas fa-toggle-off"></i></a>
                    </template>
                  </b-table>
                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="editItemId" :key="editItemId"/>
      </p>
    </b-modal>
  </b-container>
</template>
<script>
import FormV from './Form'
import { pumpSchedulerList, pumpSchedulerToggleStatus } from '../../api/routes'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { mapGetters } from 'vuex'

export default {
  mixins: [ModalBaseMasterList],
  components: { FormV },
  data () {
    return {
      search: {
        org_id: '0',
        pump_id: '0'
      },
      editItemId: 0,
      pumpInformationDropdownList: [],
      rows: [],
      projectList: [],
      districtList: [],
      upazilaList: [],
      unionList: []
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    organizationList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    formTitle () {
       return (this.testId === 0) ? this.$t('irriPumpInformation.pump_scheduler_entry') : this.$t('irriPumpInformation.pump_scheduler') + ' ' + this.$t('globalTrans.modify')
    },
    detailTitle () {
       return this.$t('irriPumpInformation.pump_scheduler_detail')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('org_pro.organization'), class: 'text-center' },
          { label: this.$t('irriPumpInformation.pump_id'), class: 'text-center' },
          { label: this.$t('irriPumpInformation.pump_on_time'), class: 'text-center' },
          { label: this.$t('irriPumpInformation.pump_off_time'), class: 'text-center' },
          { label: this.$t('pump_install.created_date'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center w-10' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'org_name_bn' },
          { key: 'pump_idd' },
          { key: 'ontime' },
          { key: 'offtime' },
          { key: 'created_at' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'org_name' },
          { key: 'pump_idd' },
          { key: 'ontime' },
          { key: 'offtime' },
          { key: 'created_at' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList
    }
  },
  watch: {
    'search.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'search.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'search.upazilla_id': function (newVal, oldVal) {
      this.unionList = this.getUnionList(newVal)
    }
  },
  created () {
    this.getPumpInformationList()
  },
  mounted () {
    if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.office_detail.org_id
      })
    } else if (this.authUser.role_id === 0) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.office_detail.org_id
      })
    }
    this.loadData()
  },
  methods: {
    searchData () {
      if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
        this.search = Object.assign({}, this.search, {
          org_id: this.authUser.office_detail.org_id
        })
      } else if (this.authUser.role_id === 0) {
        this.search = Object.assign({}, this.search, {
          org_id: this.authUser.office_detail.org_id
        })
      }
      this.loadData()
    },
    edit (item) {
      this.editItemId = item.id
    },
    remove (item) {
      this.changeStatus(irriSchemeServiceBaseUrl, pumpSchedulerToggleStatus, item)
    },
    async loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      await RestApi.getData(irriSchemeServiceBaseUrl, pumpSchedulerList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.getRelationalData(response.data))
          this.paginationData(response.data)
        }
      })
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getRelationalData (data) {
      const organizationList = this.$store.state.orgList
      return data.data.map(item => {
        const orgObject = organizationList.find(oganization => oganization.value === item.org_id)
        const orgData = {
          org_name: orgObject.text,
          org_name_bn: orgObject.text_bn
        }
        return Object.assign({}, item, orgData)
      })
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList
      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getUnionList (upazillaId = null) {
      const unionList = this.$store.state.commonObj.unionList
      if (upazillaId) {
        return unionList.filter(union => union.upazilla_id === upazillaId)
      }
      return unionList
    },
    getPumpInformationList () {
      RestApi.getData(irriSchemeServiceBaseUrl, 'pump-scheduler/list-byPump_id').then(response => {
        this.pumpInformationDropdownList = response.data.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.pump_id, text: obj.pump_id }
          } else {
            return { value: obj.pump_id, text: obj.pump_id }
          }
        })
      })
    }
  }
}
</script>
