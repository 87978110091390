export const list = '/list'
export const store = '/store'
export const update = '/update'
export const toggleStatus = '/toggle-status'
export const destroy = '/delete'
// pump operator
export const pumpOperatorList = 'pump-operator/list'
export const pumpOperatorReportList = 'pump-operator/report/list'
export const userStore = 'user/operator-store'
export const userDelete = 'user/destroy'
export const pumpOperatorStore = 'pump-operator/store'
export const pumpOperatorUpdate = 'pump-operator/update'
export const pumpOperatorImporterUpdate = 'pump-operator/importer-update'
export const pumpOperatorToggleStatus = 'pump-operator/toggle-status'
export const pumpOperatorDestroy = 'pump-operator/delete'
export const pumpOperatorExcelUpload = 'pump-operator/excel-uploader'
// pump Information
export const pumpInformationList = 'pump-information/list'
export const pumpInformationReportList = 'pump-information/report/list'
export const pumpInformationStore = 'pump-information/store'
export const pumpInformationUpdate = 'pump-information/update'
export const pumpInformationImportUpdate = 'pump-information/importer-update'
export const importFarmerUpdate = 'pump-information/importer-farmer-update'
export const pumpInformationToggleStatus = 'pump-information/toggle-status'
export const pumpExcelUpload = 'pump-information/pump-excel-uploader'
export const farmerExcelUpload = 'pump-information/farmer-excel-uploader'
// pump Scheduler
export const pumpSchedulerList = 'pump-scheduler/list'
export const pumpSchedulerStore = 'pump-scheduler/store'
export const pumpSchedulerUpdate = 'pump-scheduler/update'
export const pumpSchedulerToggleStatus = 'pump-scheduler/toggle-status'
export const pumpSchedulerDestroy = 'pump-scheduler/delete'

// pump Operator Suspension
const pumpOptSuspensionPrefix = 'pump-operator-suspension'
export const pumpOptSuspensionGetPumpList = pumpOptSuspensionPrefix + '/pump-list'
export const pumpOptSuspensionGetOperatorList = pumpOptSuspensionPrefix + '/opt-list'
export const pumpOptSuspensionList = pumpOptSuspensionPrefix + '/list'
export const pumpOptSuspensionStore = pumpOptSuspensionPrefix + '/store'

// farmer-land-details
const farLandDetails = 'farmer-land-details'
export const farStore = farLandDetails + '/store'
export const farList = farLandDetails + '/list'

// deep Tube Well Report
export const deepTubeWellReportStore = 'deep-tube-well-report/store'
export const deepTubeWellReportUpdate = 'deep-tube-well-report/update'
export const deepTubeWellReportList = 'deep-tube-well-report/list'
export const deepTubeWellReportListAll = 'deep-tube-well-report/list-all'
export const deepTubeWellReportToggleStatus = 'deep-tube-well-report/toggle-status'
export const deepTubeWellReportEntryUpdate = 'deep-tube-well-report/update'
export const getdeepTubwellEditingData = 'deep-tube-well-report/show/'
export const deepTubeWellReport = 'receive-costing-income/report'

// pump-drilling-log
export const drillingLogStore = 'pump-drilling-log/store'
export const drillingLogUpdate = 'pump-drilling-log/update'
export const drillingLogList = 'pump-drilling-log/list'
export const drillingLogReportList = 'pump-drilling-log/report'
export const drillingLogShow = 'pump-drilling-log/show/'
export const drillingLogToggleStatus = 'pump-drilling-log/toggle-status'

// pump-construction-details
export const constructionDetailsStore = 'pump-construction-details/store'
export const constructionDetailsUpdate = 'pump-construction-details/update'
export const constructionDetailsList = 'pump-construction-details/list'
export const constructionDetailsReport = 'pump-construction-details/report'
export const constructionDetailsShow = 'pump-construction-details/show/'
export const constructionDetailsToggleStatus = 'pump-construction-details/toggle-status'
